import React, { Fragment, useState } from 'react';

import { parseImageSrc } from '@common/components/default/Image/Image';
import { TokenNames } from '@common/constants/currency/settings/tokenNames';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { pageEnhancer } from '@common/hoc/pageEnhancer';
import { config } from '@legacyApp/client/config';
import { DEFAULT_NAMESPACE } from '@legacyApp/client/modules/translation/translate.constants';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { handleGetStaticProps } from '@legacyApp/methods/router/handleGetStaticProps';
import { SLOTS_SCROLL_CARD_COUNT } from '@modules/slots/constants/Slots.constants';
import { SlotsBottomSectionsContext } from '@modules/slots/context/SlotsBottomSectionsContext';
import { SlotsCategoriesContext } from '@modules/slots/context/SlotsCategoriesContext';
import { SlotsFirstSectionContext } from '@modules/slots/context/SlotsFirstSectionContext';
import { SlotsProvidersContext } from '@modules/slots/context/SlotsProvidersContext';
import { getNewSlots } from '@modules/slots/methods/getNewSlots';
import { getMainPageCategories } from '@modules/slots/methods/getMainPageCategories';
import { getSlots } from '@modules/slots/methods/getSlots';
import { getSlotsCategories } from '@modules/slots/methods/getSlotsCategories';
import { getProviders } from '@modules/slots/methods/getProviders';

import { HomeContent } from '@common/components/home/HomeContent';
import { Redirect } from '@common/components/default/Redirect/Redirect';
import { AnyJsonLd, SAME_AS } from '@common/components/seo/AnyJsonLd';
import { currenciesSettings } from '@common/controllers/currency/currenciesSettings';
import routingService from '@legacyApp/client/modules/app/routingService';

const pageDescription = () => ({
	options: {
		noParagraphTranslate: true,
		noTitleIndex: true,
		noParagraphIndex: true,
		toggle: true,
		shadowColor: '#0b1b36',
	},
	namespace: 'homePageContent',
	title: {
		message: '{{appName}} The Bitcoin & Crypto Casino',
		values: {
			appName: config.appName,
		},
	},
	content: [
		{
			title: 'question1',
			paragraphs: [
				[
					{
						message: 'answer1-1',
						values: {
							currencies: currenciesSettings.tokens
								.map((token) => TokenNames[token])
								.join(', '),
						},
					},
				],
				['answer1-2'],
				['answer1-3'],
			],
		},
		{
			title: 'question2',
			paragraphs: [
				['answer2-1'],
				[
					{
						message: 'answer2-2',
						components: (
							<Fragment key="provably-fair">
								Our current games only charge a 1% house edge and every bet
								placed on them can be 100% verified through our
								<Redirect
									target="_blank"
									rel="noreferrer"
									className="text-underline"
									href="/provably-fair?tab=overview"
								>
									<>Provably Fair</>
								</Redirect>
								page, assuring players get a fair and transparent experience.
							</Fragment>
						),
					},
				],
			],
		},
		{
			title: 'question3',
			paragraphs: [['answer3-1']],
		},
		{
			title: 'question4',
			paragraphs: [['answer4-1']],
		},
		{
			title: 'question5',
			paragraphs: [['answer5-1']],
		},
		{
			title: 'question6',
			paragraphs: [
				['answer6-1'],
				['answer6-2'],
				['answer6-3'],
				[
					{
						message: 'answer6-4',
						components: (
							<Fragment key="promotions">
								For more information about our promotions, please check our
								<Redirect
									target="_blank"
									rel="noreferrer"
									className="text-underline"
									href="/promotions"
								>
									<>Promotions</>
								</Redirect>
								page.
							</Fragment>
						),
					},
				],
			],
		},
		{
			title: 'question7',
			paragraphs: [
				['answer7-1'],
				['answer7-2'],
				[
					{
						message: 'answer7-3',
						components: (
							<Fragment key="promotions">
								For more information about our VIP system, please visit the
								<Redirect
									target="_blank"
									rel="noreferrer"
									className="text-underline"
									href={routingService.to(ROUTING_ID.USER_VIP)}
								>
									<>VIP</>
								</Redirect>
								page.
							</Fragment>
						),
					},
				],
			],
		},
	],
});

const id = ROUTING_ID.HOME;

export async function getStaticProps(props) {
	const providers = await getProviders(false, SLOTS_SCROLL_CARD_COUNT);

	const categories = await getSlotsCategories();

	const newGames = await getNewSlots(SLOTS_SCROLL_CARD_COUNT);

	const { firstSection, bottomSections } = await getMainPageCategories(
		getSlots,
		categories,
		newGames,
	);

	return await handleGetStaticProps(
		{
			locale: props.locale,
			params: {
				...props,
				providers,
				bottomSections,
				firstSection,
				categories,
			},
		},
		[
			DEFAULT_NAMESPACE,
			TransNamespace.SLOTS,
			TransNamespace.HOME_PAGE,
			TransNamespace.HOME_PAGE_CONTENT,
		],
		true,
	);
}

const Index = (context) => {
	const { params } = context;

	const { firstSection, bottomSections, providers, categories } = params;

	const [providers_, setProviders_] = useState(providers);

	const [categories_, setCategories_] = useState(categories);

	const [firstSection_, setFirstSection_] = useState(firstSection);

	const [bottomSections_, setBottomSections_] = useState(bottomSections);

	return (
		<SlotsBottomSectionsContext.Provider
			value={{
				bottomSections: bottomSections_,
				setBottomSections: setBottomSections_,
			}}
		>
			<SlotsFirstSectionContext.Provider
				value={{
					firstSection: firstSection_,
					setFirstSection: setFirstSection_,
				}}
			>
				<SlotsProvidersContext.Provider
					value={{ providers: providers_, setProviders: setProviders_ }}
				>
					<SlotsCategoriesContext.Provider
						value={{ categories: categories_, setCategories: setCategories_ }}
					>
						<HomeContent />
					</SlotsCategoriesContext.Provider>
				</SlotsProvidersContext.Provider>
			</SlotsFirstSectionContext.Provider>
		</SlotsBottomSectionsContext.Provider>
	);
};

export default pageEnhancer(Index, {
	id,
	pageDescription,
	structuredData: (
		<AnyJsonLd
			// @ts-expect-error invalid type
			props={{
				'@type': 'Corporation',
				brand: config.appName,
				logo: parseImageSrc('/img/logo_black.png'),
				contactPoint: {
					'@type': 'ContactPoint',
					email: `support@${config.domain}`,
					contactType: 'customer service',
				},
				sameAs: SAME_AS,
			}}
		/>
	),
});
