import { FC } from 'react';
import Head from 'next/head';
import { config } from '@legacyApp/client/config';
import { StructuredData } from '@legacyApp/types/default/StructuredData';
import { socialConfig } from '@server/config/social.config';
import { jsonToString } from '@common/methods/jsonToString';

interface Props extends StructuredData {
	url: string;
}

export interface AnyJsonLdProps {
	keyOverride?: string;
	props?: Props;
}

export const parseUrlJsonLd = (uri?: string) =>
	`https://${config.domain}${uri ?? ''}`;

export const SAME_AS = Object.keys(socialConfig).map(
	(key) => socialConfig[key],
);

export const AnyJsonLd: FC<AnyJsonLdProps> = ({ keyOverride, props }) => {
	const url = parseUrlJsonLd(props.url);
	const jslonld = {
		'@context': 'https://schema.org',
		name: config.appName,
		url,
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': `${url}`,
		},
		...props,
	};

	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: jsonToString(jslonld) }}
				key={`jsonld-article${keyOverride ? `-${keyOverride}` : ''}`}
			/>
		</Head>
	);
};
